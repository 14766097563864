import { useState } from 'react';
import TalkBox from './TalkBox';
import { TalkBoxType } from './TalkBox/types';
import { Typography } from '@/components/Typography';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import {
  MicrophoneIcon,
  MicrophoneMobileIcon,
  ChevronIcon
} from '@/static/assets/icons';
import './TalksAndWebinars.scss';
import { resourcesPage } from '@/utils/contentData/resourcesPage';

const { header, upcomingTalks, pastTalks } = resourcesPage;
const { title, subTitle } = header;
const { title: pastTalksTitle, items: pastTalksItems } = pastTalks;
const { title: upcomingTalksTitle, items: upcomingTalksItems } = upcomingTalks;

const TalksAndWebinars = () => {
  const [isUpcomingOpen, setIsUpcomingOpen] = useState(true);
  const [isPastOpen, setIsPastOpen] = useState(true);
  const { isBreakpointRange } = useBreakpoints();
  const getTalks = (talkType: TalkBoxType[]) =>
    talkType.map(
      ({ id, title, image, label, description, url, imageAlt, isExternal }) => (
        <TalkBox
          key={id}
          id={id}
          image={image}
          label={label}
          title={title}
          description={description}
          url={url}
          imageAlt={imageAlt}
          isExternal={isExternal}
        />
      )
    );

  const getPastTalks = getTalks(pastTalksItems);
  const getUpcomingTalks = getTalks(upcomingTalksItems);

  const handleOpenUpcomingCarot = () =>
    setIsUpcomingOpen((prevState) => !prevState);
  const handleOpenPastCarot = () => setIsPastOpen((prevState) => !prevState);

  const isMobileChevronOpen = (isEventOpen: boolean) =>
    isBreakpointRange('tablet') && isEventOpen;
  const isMobileChevronClose = (isEventOpen: boolean) =>
    isBreakpointRange('tablet') && !isEventOpen;

  return (
    <div className={'talks-and-webinars position-relative'}>
      <div className={'talks-and-webinars__header'}>
        <Typography
          margin={[2, 0]}
          component={'h2'}
          typographyKind={'header-primary-large'}
          className={'talks-and-webinars__header-title'}
        >
          {isBreakpointRange('tablet') ? (
            <MicrophoneMobileIcon />
          ) : (
            <MicrophoneIcon />
          )}
          <span className="talks-and-webinars__header-title-text">{title}</span>
        </Typography>
        <Typography
          margin={[2, 0]}
          component={'p'}
          typographyKind={'text-primary-medium-regular'}
          className={'talks-and-webinars__header-subtitle'}
          size={24}
        >
          {subTitle}
        </Typography>
      </div>
      <div
        className={
          'talks-and-webinars__section__container container-fluid content--max-width'
        }
      >
        <div className="talks-and-webinars__section-upcoming position-relative">
          <Typography
            margin={[16, 0]}
            component={'h3'}
            typographyKind={'header-secondary-large-bold'}
            className={'talks-and-webinars__section-title'}
          >
            {upcomingTalksTitle}
          </Typography>
          {isMobileChevronOpen(isUpcomingOpen) ? (
            <>
              <div className={'row flex justify-center'}>
                {getUpcomingTalks}
              </div>
              <ChevronIcon
                className="talks-and-webinars__section-carot"
                onClick={handleOpenUpcomingCarot}
              />
            </>
          ) : isMobileChevronClose(isUpcomingOpen) ? (
            <ChevronIcon
              className="talks-and-webinars__section-carot talks-and-webinars__section-carot-down"
              onClick={handleOpenUpcomingCarot}
            />
          ) : (
            <div className={'row flex'}>{getUpcomingTalks}</div>
          )}
        </div>
        <div className="talks-and-webinars__section-past position-relative">
          <Typography
            margin={[16, 0]}
            component={'h3'}
            typographyKind={'header-secondary-large-bold'}
            className={'talks-and-webinars__section-title'}
          >
            {pastTalksTitle}
          </Typography>
          {isMobileChevronOpen(isPastOpen) ? (
            <>
              <div className={'row flex justify-center'}>{getPastTalks}</div>
              <ChevronIcon
                className="talks-and-webinars__section-carot "
                onClick={handleOpenPastCarot}
              />
            </>
          ) : isMobileChevronClose(isPastOpen) ? (
            <ChevronIcon
              className="talks-and-webinars__section-carot talks-and-webinars__section-carot-down"
              onClick={handleOpenPastCarot}
            />
          ) : (
            <div className={'row flex'}>{getPastTalks}</div>
          )}
        </div>
      </div>
      <div
        className={'talks-and-webinars__background-gradient no-global-padding'}
      />
    </div>
  );
};

export default TalksAndWebinars;
