import { TalkBoxType } from './types';
import Image from '@/components/Image';
import { Typography } from '@/components/Typography';
import './TalkBox.scss';

const TalkBox = ({
  id,
  label: { icon: Icon, label },
  title,
  image,
  description = '',
  url,
  isExternal = false
}: TalkBoxType) => {
  return (
    <a
      className={'talk-box col-lg-4 col-md-6 col-sm-12'}
      key={id}
      href={url}
      target={isExternal ? '_blank' : '_self'}
      rel="noreferrer"
    >
      <div className="position-relative talk-box__image-wrapper">
        <Image imageUrl={image} alt={'selected-features-hero-imaage'} />
        <div className={'talk-box__label-box-wrapper'}>
          <div className="talk-box__label-wrapper">
            <Icon />
            <Typography
              component={'span'}
              typographyKind={'text-secondary-medium'}
              className={'talk-box__label-title'}
              size={14}
            >
              {label}
            </Typography>
          </div>
        </div>
      </div>
      <Typography
        component={'p'}
        typographyKind={'text-primary-extra-large-medium'}
        className={'talk-box__title text-align-left'}
        margin={[4, 0]}
      >
        {title}
      </Typography>
      <div
        className={'flex-row flex-align-items-center home-post__link-wrapper'}
      >
        <Typography
          component="p"
          typographyKind={'text-secondary-medium'}
          className={'talk-box__bio'}
        >
          {description}
        </Typography>
      </div>
    </a>
  );
};

export default TalkBox;
