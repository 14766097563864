import Layout from '@/components/Layout';
import { HomePageCTA } from '@/sections/Home/CTA';
import TalksAndWebinars from '@/sections/ResourcesPage/components/TalksAndWebinars';
import { resourcesPage } from '@/utils/contentData/resourcesPage';
import { seoConfiguration } from '@/utils/seo-config';

const { title, subTitle } = resourcesPage.cta;

const TalksAndWebinarsPage = () => {
  const { title: titleSeo, image, description } = seoConfiguration.resources;

  return (
    <Layout image={image} title={titleSeo} description={description}>
      <TalksAndWebinars />
      <HomePageCTA header={title} subheader={subTitle} />
    </Layout>
  );
};

export default TalksAndWebinarsPage;
